<template>
	<div>关于我们</div>
</template>
<script>
export default {
	data() {
		return {}
	},
	props: {},
	components: {},

	computed: {},

	mounted() {},

	methods: {}
}
</script>
<style scoped lang="scss"></style>
